import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { Id, isId } from 'api/Id'
import Modal from 'ui/Modal'
import {
  shippingTruckRunUpdate,
  ShippingTruckRunForUpdate,
  shippingTruckRunGet,
  ShippingTruckRunStatus as Status,
  isArchiveStatus
} from 'api/shipping'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import Select from 'ui/Select'
import { Driver, driverAllList, DriverStatus } from 'api/driver'
import { Trailer, trailerAllList, TrailerStatus } from 'api/trailer'
import { Vehicle, vehicleAllList, VehicleStatus } from 'api/vehicle'
import { personName } from 'util/personName'
import vehicleName from 'util/vehicleName'
import trailerName from 'util/trailerName'
import getNameStatusTruckRun from 'common/getNameStatusTruckRun'
import TextField from 'ui/TextField'
import { ForwarderList, forwarderList as apiForwarderList } from 'api/forwarder'
import { useAuthContext } from 'AuthContext'
import InfoCard from 'ui/InfoCard'
import Upload from 'ui/Upload'
import DatePicker from 'ui/DatePicker'
import { toKilogram, toTon } from 'util/weight'
import SelectAddress from 'ui/SelectAddress'
import SelectOrganization from 'ui/SelectOrganization'
import TsToFormatDate from 'util/TsToFormatDate'
import { AstonTrafficReservedSlot, astonTrafficReserveCancel } from 'api/aston-traffic'
import { useEnqueueSnackbar } from 'util/useEnqueueSnackbar'
import valueMethod from 'util/valueMethod'
import { toKopeck, toRuble } from 'util/monetary'
import { BidCargo } from 'api/bid'
import formatCargo from 'util/formatCargo'
import { Interface } from 'api/Interface'

export interface Params {
  id?: Id
  cargoList?: BidCargo[]
  onClose: () => void
  onSave: () => void
}

type Update = Omit<ShippingTruckRunForUpdate, 'id'>

export default function TruckRunEditModel ({ id, cargoList = [], onClose, onSave }: Params) {
  const [data, setData] = useState<Update>()
  const [currentStatus, setCurrentStatus] = useState<Status>()
  const [nextStatus, setNextStatus] = useState<Status>()
  const [nextStatuses, setNextStatuses] = useState<Status[]>([])
  const [driverList, setDriverList] = useState<Driver[]>([])
  const [vehicleList, setVehicleList] = useState<Vehicle[]>([])
  const [trailerList, setTrailerList] = useState<Trailer[]>([])
  const [forwarderList, setForwarderList] = useState<ForwarderList[]>([])
  const [errors, setErrors] = useState<Awaited<ReturnType<typeof shippingTruckRunUpdate>>['conflicts'] & { cancelReservation?: string }>()
  const { handleResponseSuccess, handleResponseFailure, currentInterface } = useAuthContext()
  const [errorField, setErrorField] = useState<boolean>(false)
  const [num, setNum] = useState<string>()
  const [bidCargo, setBidCargo] = useState<BidCargo>()
  const [autoFill, setAutoFill] = useState<boolean>(false)

  const forwarderInterface = currentInterface ? [Interface.forwarder, Interface.chief_forwarder].includes(currentInterface) : false

  const { toast } = useEnqueueSnackbar()

  const init = useCallback(async () => {
    const result = isId(id) ? await shippingTruckRunGet(id) : null

    if (result === null) {
      setData(undefined)
      return
    }

    const {
      state,
      status,
      owner,
      // loadingTs,
      problemComment,
      billNumber,
      billTs,
      scanBills,
      weight,
      finalWeight,
      driver,
      vehicle,
      trailer,
      forwarder,
      unloadingAddress,
      consignee,
      costCorrection,
      num,
      actualCargo,
      bid
    } = result

    setData({
      billNumber,
      billTs,
      scanBills,
      weight,
      finalWeight,
      problemComment,
      driverId: driver.id,
      vehicleId: vehicle.id,
      trailerId: trailer === undefined ? undefined : trailer.id,
      forwarderId: forwarder?.id,
      unloadingAddressId: unloadingAddress?.id,
      consigneeId: consignee?.id,
      costCorrection,
      actualCargoSlug: actualCargo?.slug
    })
    setNextStatuses(state.nextStatuses)
    setCurrentStatus(status)
    setNextStatus(status)
    setNum(num)
    setBidCargo(bid.cargo)
    setAutoFill(bid.autoFill ?? false)

    if (forwarderInterface) {
      return
    }

    // Фильтр водителей, ТС и прицепов по использование в других заявках
    // const filter = <T, >(id: Id) => (item: T & { id: Id }) => item.id !== id
    // driverAllList({ owner, status: DriverStatus.active, loadingTs }).then(r => setDriverList([...r.filter(filter(driver.id)), driver]))
    // vehicleAllList({ owner, status: VehicleStatus.active, loadingTs }).then(r => setVehicleList([...r.filter(filter(vehicle.id)), vehicle]))
    // trailerAllList({ owner, status: TrailerStatus.active, loadingTs }).then(r => setTrailerList([...r.filter(filter(trailer.id)), trailer]))

    driverAllList({ owner, status: DriverStatus.active }).then(setDriverList)
    vehicleAllList({ owner, status: VehicleStatus.active }).then(setVehicleList)
    trailerAllList({ owner, status: TrailerStatus.active }).then(setTrailerList)
    apiForwarderList().then(setForwarderList)
  }, [id, forwarderInterface])

  useEffect(() => {
    init()
  }, [init])

  const handleCancel = useCallback(() => {
    setData(undefined)
    setCurrentStatus(undefined)
    setNextStatuses([])
    setErrors(undefined)
    onClose()
  }, [onClose])

  const save = async () => {
    if (!isId(id)) {
      return
    }

    if (nextStatus === Status.way && (data?.billNumber === undefined || data.weight === undefined || (data.forwarderId === undefined && !autoFill))) {
      setErrorField(true)
      return
    }

    if (nextStatus === Status.arrived && data?.finalWeight === undefined) {
      setErrorField(true)
      return
    }

    if (nextStatus === Status.completed && (data?.scanBills === undefined || data.scanBills.length === 0)) {
      setErrorField(true)
      return
    }

    if (nextStatus === Status.archiveProblem && data?.problemComment === undefined) {
      setErrorField(true)
      return
    }

    const { success, conflicts } = await shippingTruckRunUpdate({ id, ...data, status: !forwarderInterface ? nextStatus : undefined })

    if (success) {
      onSave()
      init()
      handleResponseSuccess('Данные изменены')
      forwarderInterface && handleCancel()
      return
    }

    handleResponseFailure('Данные не были изменены')
    if (conflicts === undefined) {
      handleCancel()
      return
    }

    setErrors(conflicts)
  }

  const onChangeSelect = (key: keyof Update) => {
    return (value: unknown) => {
      if (isId(value)) {
        setData(prev => ({ ...prev, [key]: value }))
      }
    }
  }

  const cancelReservation = (truckRunId: Id | undefined, slot: AstonTrafficReservedSlot) => {
    if (!isId(truckRunId)) {
      return
    }

    const { reservedId, from, to } = slot
    astonTrafficReserveCancel(truckRunId, reservedId)
      .then(result => {
        const { success, conflicts } = result

        if (!success) {
          if (conflicts && conflicts.astonMessage) {
            setErrors((prev) => ({ ...prev, cancelReservation: `ЭО Астон-Трафик: ${conflicts.astonMessage}` }))
            return
          }

          setErrors((prev) => ({ ...prev, cancelReservation: 'В данный момент невозможно отменить запись, попробуйте позже' }))
          return
        }

        toast(`Слот на ${TsToFormatDate(from, 'HH:mm')}-${TsToFormatDate(to, 'HH:mm')} ${TsToFormatDate(from, 'dd.MM')} отменен`, 'success')
        setErrors(undefined)
      })
  }

  const updateNumber = (key: keyof Update) => {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setData(prev => ({ ...prev, [key]: value === '' ? undefined : toKilogram(Number(value)) }))
  }

  const updateId = (key: keyof Update) => {
    return (id?: Id) => setData({ ...data, [key]: id })
  }

  const updateString = (key: keyof Update) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const { target: { value } } = e
      setData(prev => ({ ...prev, [key]: value }))
    }
  }

  const editBasic = (status?: Status) => status ? [Status.new, Status.confirmed, Status.way, Status.arrived, Status.completed].includes(status) : false
  const editWayStatus = (status?: Status) => status ? ![Status.confirmed, Status.archiveOutdated, Status.way].includes(status) : false

  return (<Modal
    maxWidth='lg'
    open={!!id}
    onClose={handleCancel}
    title={`Редактировать данные о рейсе ${num}`}
    content={ errors === undefined
      ? <Box minWidth='40em' >
          <InfoCard title={!forwarderInterface ? 'Статус' : undefined}><>
            { !forwarderInterface && <Select
              label='Сменить статус'
              options={ [...new Set(nextStatuses && currentStatus ? [...nextStatuses, currentStatus] : [])].map((value) => ({ value, name: getNameStatusTruckRun(value) })) }
              value={nextStatus}
              onChange={setNextStatus}
              disabled={nextStatuses === undefined || nextStatuses.length === 0 || forwarderInterface}
              width='100%'
            /> }
            { nextStatus === Status.way && <>
              { !forwarderInterface && !autoFill && <Select
                label='Экспедитор'
                placeholder='Выберите экспедитора'
                options={ forwarderList.map((item) => ({ value: item.id, name: personName(item) })) }
                value={data?.forwarderId}
                onChange={onChangeSelect('forwarderId')}
                disabled={editWayStatus(currentStatus)}
                errorMessage={errorField && data?.forwarderId === undefined ? 'Обязательно к заполнению' : undefined}
                width='100%'
              />}
              { cargoList.length > 0 && <Select
                name='actualCargoSlug'
                label='Фактический груз'
                hint={bidCargo?.name}
                placeholder='Выберите груз'
                options={cargoList
                  .sort(item => {
                    if (!bidCargo?.externalId || !item.productBaseId) {
                      return 0
                    }

                    if (bidCargo.externalId === item.productBaseId) {
                      return -1
                    }

                    return 1
                  })
                  .map(item => ({ value: item.slug, name: formatCargo(item) }))}
                value={data?.actualCargoSlug}
                onChange={actualCargoSlug => setData(prev => ({ ...prev, actualCargoSlug }))}
                errorMessage={errorField && data?.actualCargoSlug === undefined ? 'Обязательно к заполнению' : undefined}
              /> }
              <DatePicker
                name='billTs'
                label='Дата ТТН/ТрН'
                value={data?.billTs}
                onChange={billTs => setData(prev => ({ ...prev, billTs }))}
                disabled={editWayStatus(currentStatus)}
                width='100%'
                errorMessage={errorField && data?.billTs === undefined ? 'Обязательно к заполнению' : undefined}
              />
              <TextField
                label='Номер ТрН/ТТН'
                placeholder='Номер ТрН/ТТН'
                value={data?.billNumber}
                onChange={updateString('billNumber')}
                disabled={editWayStatus(currentStatus)}
                errorMessage={errorField && data?.billNumber === undefined ? 'Обязательно к заполнению' : undefined}
              />
              <TextField
                label='Вес погрузки, тн'
                placeholder='Вес погрузки, тн'
                value={(data?.weight !== undefined && data?.weight > 0) ? toTon(data.weight) : undefined}
                typeNumber
                onChange={updateNumber('weight')}
                disabled={editWayStatus(currentStatus)}
                errorMessage={errorField && data?.weight === undefined ? 'Обязательно к заполнению' : undefined}
              />
              { !forwarderInterface && <SelectAddress
                label='Название пункта разгрузки (переадресация)'
                placeholder='Выберите пункт зазгрузки'
                onChange={updateId('unloadingAddressId')}
                value={data?.unloadingAddressId}
                itemName
                excludeIds={data?.bid ? [data.bid.unloadingAddress.id] : []}
              /> }
              { !forwarderInterface && <SelectOrganization
                label='Грузополучатель'
                placeholder='Выберете грузополучателя'
                value={data?.consigneeId}
                onChange={updateId('consigneeId')}
              /> }
              { !forwarderInterface && <TextField
                label='Добавочная стоимость, руб.'
                value={valueMethod(data?.costCorrection)(toRuble)}
                typeNumber
                onChange={({ target: { value } }) => setData((data) => ({ ...data, costCorrection: value === '' ? undefined : toKopeck(Number(value)) }))}
              /> }
            </> }
            { nextStatus === Status.arrived && <>
              <TextField
                label='Вес разгрузки, тн'
                placeholder='Вес разгрузки, тн'
                value={(data?.finalWeight !== undefined && data?.finalWeight > 0) ? toTon(data.finalWeight) : undefined}
                typeNumber
                onChange={updateNumber('finalWeight')}
                disabled={currentStatus === Status.arrived}
                errorMessage={errorField && data?.finalWeight === undefined ? 'Обязательно к заполнению' : undefined}
              />
              <SelectAddress
                label='Название пункта разгрузки (переадресация)'
                placeholder='Выберите пункт зазгрузки'
                onChange={updateId('unloadingAddressId')}
                value={data?.unloadingAddressId}
                itemName
              />
              <SelectOrganization
                label='Грузополучатель'
                placeholder='Выберете грузополучателя'
                value={data?.consigneeId}
                onChange={updateId('consigneeId')}
              />
              <TextField
                label='Добавочная стоимость, руб.'
                value={valueMethod(data?.costCorrection)(toRuble)}
                typeNumber
                onChange={({ target: { value } }) => setData((data) => ({ ...data, costCorrection: value === '' ? undefined : toKopeck(Number(value)) }))}
              />
            </> }
            { nextStatus === Status.completed && <Stack>
              {data?.scanBills && data.scanBills.map((scanBill, idx) =>
                <Upload
                  key={idx}
                  label={idx === 0 ? 'Фото ТрН/ТТН' : undefined}
                  value={scanBill}
                  onChange={() => setData((prev) => {
                    const scanBills = prev?.scanBills ? [...prev.scanBills] : []
                    scanBills.splice(idx, 1)
                    return { ...prev, scanBills: scanBills.length > 0 ? scanBills : undefined }
                  })}
                />
              )}
              <Upload
                key='new'
                label={(data?.scanBills ?? []).length === 0 ? 'Фото ТрН/ТТН' : undefined}
                onChange={(value) => setData((prev) => ({ ...prev, scanBills: [...(prev?.scanBills ?? []), value] })) }
                disabled={currentStatus === Status.completed}
                errorMessage={errorField && (data?.scanBills ?? []).length === 0 ? 'Обязательно к заполнению' : undefined}
              />
            </Stack> }
            { nextStatus === Status.archiveProblem && <>
              <TextField
                label='Комментарий'
                placeholder='Укажите причину'
                rows={3}
                value={data?.problemComment}
                onChange={updateString('problemComment')}
                disabled={currentStatus === Status.archiveProblem}
                errorMessage={errorField && data?.problemComment === undefined ? 'Обязательно к заполнению' : undefined}
              />
            </> }
          </></InfoCard>
          { (nextStatus && !isArchiveStatus(nextStatus) && !forwarderInterface) && <InfoCard title='Основная информация' >
            <>
              <Select
                label='Водитель'
                placeholder='Выберите водителя'
                options={ driverList.map(item => ({ value: item.id, name: personName(item) })) }
                value={data?.driverId}
                onChange={onChangeSelect('driverId')}
                disabled={!editBasic(currentStatus)}
                width='100%'
              />
              <Select
                label='ТС'
                placeholder='Выберите ТС'
                options={ vehicleList.map(item => ({ value: item.id, name: vehicleName(item) })) }
                value={data?.vehicleId}
                onChange={onChangeSelect('vehicleId')}
                disabled={!editBasic(currentStatus)}
                width='100%'
              />
              <Select
                label='Прицеп'
                placeholder='Выберите прицеп'
                options={ trailerList.map(item => ({ value: item.id, name: trailerName(item) })) }
                value={data?.trailerId}
                onChange={onChangeSelect('trailerId')}
                disabled={!editBasic(currentStatus)}
                width='100%'
              />
            </>
          </InfoCard>}
      </Box>
      : <Box minWidth='30em' >
        <Alert severity='error'>
          <Typography>Невозможно сохранить изменения!</Typography>
          { errors.notFound && <Typography>Рейс не найден</Typography> }
          { errors.forwarderNotFound && <Typography>Выбранный экспедитор не найден</Typography> }
          { (errors.incorrectStatus && errors.currentStatus) && <Typography>Нельзя изменить статус '{getNameStatusTruckRun(errors.currentStatus)}' на '{getNameStatusTruckRun(errors.incorrectStatus)}'</Typography> }
          { errors.reservedSlot && <Typography>
            Есть запись в пункт выгрузки, слот {TsToFormatDate(errors.reservedSlot.from, 'HH:mm')}-{TsToFormatDate(errors.reservedSlot.to, 'HH:mm')} на {TsToFormatDate(errors.reservedSlot.from, 'dd.MM.yyyy')}.<br />
            Необходимо отменить запись, для возможности редактирования
          </Typography> }
          { errors.billNumberExists && <Typography>
            Номер ТрН/ТТН {data?.billNumber} используется в другом рейсе. Введите другой номер
          </Typography> }
        </Alert>
      </Box>
    }
    actions={<Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
      <Button variant='outlined' onClick={handleCancel} >Отменить</Button>
      { errors === undefined && <Button variant='contained' onClick={save} >Cохранить</Button>}
      { errors?.reservedSlot !== undefined && <Button variant='contained' onClick={() => cancelReservation(id, errors.reservedSlot as AstonTrafficReservedSlot)} >Отменить запись</Button>}
    </Stack>}
  />)
}
